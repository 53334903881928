import React, { useState } from "react"
import { graphql } from "gatsby"
import Alert from "react-bootstrap/Alert"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Table from "react-bootstrap/Table"
import { Calendar3 } from "react-bootstrap-icons"
import JumbotronFluid from "../../components/JumbotronFluid"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import Seo from "../../components/Seo"

export const Head = () => {
  return <Seo customTitle="Termine" />
}

export default function Termine({ data }) {
  const { nodes: events } = data.allEventsXlsEvents
  const currentYear = new Date().getFullYear()
  const dateFormat = {
    lang: "de-DE",
    options: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
  }
  const [showAlert, setShowAlert] = useState(true)

  return (
    <Layout>
      <Container className="my-5">
        <PageTitle title="Termine" />
        <p className="lh-text mb-5 lead font-weight-normal">
          Diese Tabelle beinhaltet alle zukünftigen Termine.
        </p>
        <Alert
          dismissible
          show={showAlert}
          onClose={() => setShowAlert(false)}
          variant="info"
        >
          <Alert.Heading>Stand: Juni 2024</Alert.Heading>
          <p>Schauen Sie öfter vorbei, um keine neuen Termine zu verpassen.</p>
        </Alert>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Veranstaltung</th>
            </tr>
          </thead>
          <tbody>
            {events.map(evt => {
              const eventHasDifferentStartAndEndDate =
                new Date(evt.dateStart) - new Date(evt.dateEnd) !== 0
              return (
                <tr key={evt.id}>
                  <td>
                    <time dateTime={evt.dateStart}>
                      {evt.dateStart
                        ? new Date(evt.dateStart).toLocaleDateString(
                            dateFormat.lang,
                            dateFormat.options,
                          )
                        : null}
                    </time>
                    {eventHasDifferentStartAndEndDate ? (
                      <>
                        {" - "}
                        <time dateTime={evt.dateEnd}>
                          {evt.dateEnd
                            ? new Date(evt.dateEnd).toLocaleDateString(
                                dateFormat.lang,
                                dateFormat.options,
                              )
                            : null}
                        </time>
                      </>
                    ) : null}
                  </td>
                  <td>{evt.event}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Container>
      <JumbotronFluid backgroundColor="light" textColor="dark">
        <Row className="text-center text-md-left">
          <Col className="mb-5 mb-md-0" md={6} lg={5} xl={4}>
            <Calendar3 className="text-muted" style={{ fontSize: "10rem" }} />
          </Col>
          <Col>
            <p className="h5 lh-text">
              Zu unseren Veranstaltungen im Jahr {currentYear} bitten wir alle
              Mitglieder und Freunde um rege Beteiligung.
            </p>
            <p className="lh-text my-5 my-lg-4 my-xl-5 mb-0">
              Arbeiten ums Clubheim finden im Zeitraum Frühling bis Herbst,
              jeweils Dienstags vor dem Clubabend um 19:00 Uhr, statt.
              Clubabende finden alle zwei Wochen, Donnerstags ab 20:00 Uhr,
              statt.
            </p>
          </Col>
        </Row>
      </JumbotronFluid>
    </Layout>
  )
}

// Fetch all events from excel file
export const query = graphql`
  query eventsQuery {
    allEventsXlsEvents {
      nodes {
        id
        dateEnd
        dateStart
        event
      }
      totalCount
    }
  }
`
