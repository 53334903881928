import React from "react"
import Container from "react-bootstrap/Container"
import Jumbotron from "react-bootstrap/Jumbotron"

export default function jumbotronFluid({
  backgroundColor = "gray",
  textColor = "dark",
  children,
}) {
  return (
    <Jumbotron fluid className={`bg-${backgroundColor} text-${textColor}`}>
      <Container>{children}</Container>
    </Jumbotron>
  )
}
